import React, { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import TagCloudWidget from '../Widget/TagCloudWidget';
import RecentPostWidget from '../Widget/RecentPostWidget';
import SearchWidget from '../Widget/SearchWidget';
import CategoryWidget from '../Widget/CategoryWidget';
import PostStyle4 from '../Post/PostStyle4';
import ProjectSliderWidget from '../Widget/ProjectSliderWidget';
import { pageTitle } from '../../helpers/PageTitle';

const postData = [
    {
        imgUrl: '/images/post_11.jpeg',
        title: 'Java Developer',
        subTitle:
            "We are seeking a highly skilled Java Developer with expertise in Microservices architecture and Spring Security to join our dynamic team. The successful candidate will play a key role in designing, developing, and implementing scalable and secure Java-based applications.",
        srcUrl: '/Java-Developer',
        postedBy: 'Admin',
        adminUrl: '/',
        numberOfComment: 19,
        commentUrl: '/blog/blog-details',
    },
    {
        imgUrl: '/images/post_11.jpeg',
        title: 'Senior .Net Developer',
        subTitle:
            "Involve in software development life cycle (SDLC), which includes requirement gathering, design, coding, testing. Design and develop the application using framework Angular/React with HTML5, CSS3 and Bootstrap.",
        srcUrl: '/Senior-Developer',
        postedBy: 'Admin',
        adminUrl: '/',
        numberOfComment: 19,
        commentUrl: '/blog/blog-details',
    },
  {
    imgUrl: '/images/post_10.jpeg',
      title: 'Software Developer',
    subTitle:
      "Develop, create, and modify general computer applications software or specialized utility programs. Analyze user needs and develop software solutions.",
      srcUrl: '/Software-Developer',
    postedBy: 'Admin',
    adminUrl: '/',
    numberOfComment: 5,
    commentUrl: '/blog/blog-details',
  },
    {
        imgUrl: '',
        title: 'Software Engineer',
        subTitle:
            "Seeking qualified Software Engineer with Master's Degree or foreign equivalent CS OR ENGG OR CIS OR IT to Develop frond end using HTML",
        srcUrl: '/Software-Engineer',
        postedBy: 'Admin',
        adminUrl: '/',
        numberOfComment: 3,
        commentUrl: '/blog/blog-details',
    },
];

const categoryData = [
  'Corporate',
  'Digital Solutions',
  'Business & Finance',
  'Web Designer',
  'Social Marketing',
];

const recentPost = [
    {
        imgUrl: '',
        title: 'Java Developer',
        date: '03 June, 2024',
        srcUrl: '/Java-Developer',
    },
    {
        imgUrl: '',
        title: 'Senior .Net Developer',
        date: '20 May, 2024',
        srcUrl: '/Senior-Developer',
    },
    {
        imgUrl: '',
        title: 'Software Developer',
        date: '15 May, 2024',
        srcUrl: '/Software-Developer',
    },
    {
        imgUrl: '',
        title: 'Software Engineer',
        date: '3 May, 2024',
        srcUrl: '/Software-Engineer',
    }
];

const projectData = [
  {
    imgUrl: '/images/sidebar-project-1.jpeg',
    title: 'Technology',
    srcUrl: '/portfolio/portfolio-details',
  },
  {
    imgUrl: '/images/sidebar-project-1.jpeg',
    title: 'Digital Marketing',
    srcUrl: '/portfolio/portfolio-details',
  },
];

const tagData = [
  'Social Marketing',
  'Marketing',
  'Digital Market',
  'Development',
  'Web Design',
  'Design',
];

export default function BlogListPage() {
  pageTitle('Careers');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb title="Latest jobs" bgUrl="/images/career.jpg" />
      <section className="cs_pt_140 cs_pt_lg_80 cs_pb_100 cs_pb_lg_80">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {postData?.map((item, index) => (
                <PostStyle4 {...item} key={index} />
              ))}
            </div>
            <div className="col-lg-4">
              <div className="cs_sidebar">
                {/*<SearchWidget />*/}
                {/*<CategoryWidget data={categoryData} title="Category" />*/}
                <RecentPostWidget data={recentPost} title="Recent Post" />
                {/*<ProjectSliderWidget data={projectData} title="Project" />*/}
              {/*  <TagCloudWidget data={tagData} title="Tags" />*/}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
