import React, { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import SocialBtns from '../SocialBtns';
import { Icon } from '@iconify/react';
import { pageTitle } from '../../helpers/PageTitle';

export default function DetailsPage() {
  pageTitle('Career');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
          <Breadcrumb title="Career" bgUrl="/images/career.jpg" />

      <section className="cs_pt_130 cs_pt_lg_80 cs_pb_93 cs_pb_lg_80">
        <div className="container">
          <div>
            <div className="cs_project_details-head d-flex align-items-center justify-content-between flex-wrap cs_mb_40 cs_row_gap_15 cs_column_gap_15">
              <h2 className="m-0 cs_fs_48 cs_fs_lg_36">Software Engineer </h2>
              <div className="cs_published_date d-inline-flex cs_rounded_5 bg-accent text-white cs_pt_5 cs_pb_5 cs_pl_15 cs_pr_15 cs_pt_lg_3 cs_pb_lg_3">
                              3 May, 2024
              </div>
            </div>
            {/*<img*/}
            {/*  src="/images/project_details_img.jpeg"*/}
            {/*  alt="Thumb"*/}
            {/*  className="cs_mb_60 cs_mb_lg_40"*/}
            {/*/>*/}
            <div className="row">
              <div className="col-lg-8">
                <div className="cs_project_details cs_pb_40">
                                  <p className="m-0">
                                      <h3 className="text-accent cs_fs_20 cs_lh_base m-0">Roles and Responsibilities</h3>
                                      Develop and maintain robust web applications using .NET, C#, ASP.NET, and other relevant technologies.
                                      Work collaboratively in a team environment to design and implement functional, client-facing features that enhance user experience.
                                      Utilize RESTful API design patterns to integrate and improve application effectiveness.
                                      Engage in a full software development lifecycle including testing, integration, and deployment.
                                      Ensure high performance and responsiveness of applications across multiple platforms.
                                      Maintain code integrity and organization, utilizing version control tools like Git.
                                      Collaborate with other team members and stakeholders to achieve business goals.
                  </p>{' '}
                  <br />
                  <h3 className="text-accent cs_fs_20 cs_lh_base m-0">
                                      Required Skills and Qualifications:
                  </h3>{' '}
                  <br />
                  <p className="cs_mb_35">
                                      Proficiency in C#, .NET Core, ASP.NET, and REST/API design patterns.
                                      Experience with HTML, CSS, Javascript and a keen eye for UX/UI design.
                                      Solid understanding of object-oriented programming, multi-tiered architecture, SQL/TSQL.
                                      Familiarity with cloud services (AWS, Azure, GCP).
                                      Excellent problem-solving abilities and a strong work ethic.
                                      Effective communication skills, both verbal and written.
                  </p>
                  {/*<h4 className="cs_fs_18 cs_mb_12">Project Challenges : </h4>*/}
                  {/*<ul className="cs_mb_30 cs_list cs_style_1">*/}
                  {/*  <li>*/}
                  {/*    <Icon icon="fa-solid:check" />*/}
                  {/*    Nemo enim ipsam voluptatem quia voluptas mus et iusto odio*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    <Icon icon="fa-solid:check" />*/}
                  {/*    Vivamus vel eros vitae est aliquet pellentesque*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    <Icon icon="fa-solid:check" />*/}
                  {/*    If you are going to use a passage of Lorem Ipsum*/}
                  {/*  </li>*/}
                                  {/*</ul>*/}
                                  <h3 className="text-accent cs_fs_20 cs_lh_base m-0">
                                      Qualified Candidates Should Send Their Resumes To:
                                  </h3>{' '}
                  <p className="m-0">
                                      <a href="mailto:hr@crescentitsolutions.com" className="text-accent cs_fs_20 cs_lh_base m-0">hr@crescentitsolutions.com</a> Or Drop at 
                                      Crescent IT Solutions Inc. 5901 N Cicero Ave, Suite 203 Chicago, IL 60646 Phone: <a href="tel:7086670871">
                                          (708) 667-0871</a>
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <ul className="cs_project_details-info bg-white cs_rounded_20 m-0 cs_pl_50 cs_pt_50 cs_pr_50 cs_pb_50 cs_pl_lg_40 cs_pt_lg_40 cs_pr_lg_40 cs_pb_lg_40">
                  <li>
                    <h3 className="cs_fs_18 cs_mb_10">Position:</h3>
                    <p className="m-0">Software Engineer</p>
                  </li>
                  {/*<li>*/}
                  {/*  <h3 className="cs_fs_18 cs_mb_10">Client:</h3>*/}
                  {/*  <p className="m-0">Kristin Watson</p>*/}
                                  {/*</li>*/}
                                 
                                  <li>
                                      <h3 className="cs_fs_18 cs_mb_10">Date of Opening :</h3>
                                      <p className="m-0">03 May 2024</p>
                                  </li>
                                
                                  <li>
                                      <h3 className="cs_fs_18 cs_mb_10">Date of Closing :</h3>
                                      <p className="m-0">OPEN</p>
                                  </li>
                                  <li>
                                      <h3 className="cs_fs_18 cs_mb_10">Working Days and Hours: :</h3>
                                      <p className="m-0">Monday to Friday, 9:00 am {'-'} 5:00 pm</p>
                                  </li>
                                  <li>
                                      <h3 className="cs_fs_18 cs_mb_10">Salary :</h3>
                                      <p className="m-0"> $45,000 {'-'} $60,000 Per Annum</p>
                                  </li>
                  <li>
                    <h3 className="cs_fs_18 cs_mb_10">Location :</h3>
                    <p className="m-0">{/*5901 N Cicero Ave, */}Chicago, IL</p>
                  </li>
                  {/*<li>*/}
                  {/*  <SocialBtns variant="cs_social_btns d-flex flex-wrap cs_column_gap_15 cs_row_gap_15 cs_transition_5" />*/}
                  {/*</li>*/}
                </ul>
              </div>
            </div>
          </div>
        </div>
          </section>

    </>
  );
}
