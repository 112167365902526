import React, { useEffect } from 'react';
import HeroStyle5 from '../Hero/HeroStyle5';
import Section from '../Section';
import MovingTextSection from '../Section/MovingTextSection';
import AboutUsSection from '../Section/AboutSection';
import PortfolioSectionStyle2 from '../Section/PortfolioSection/PortfolioSectionStyle2';
import TestimonialSectionStyle2 from '../Section/TestimonialSection/TestimonialSectionStyle2';
import TeamSectionStyle2 from '../Section/TeamSection/TeamSectionStyle2';
import FunfactSection from '../Section/FunfactSection';
import ContactSection from '../Section/ContactSection';
import PostSectionStyle5 from '../Section/PostSection/PostSectionStyle5';
import { pageTitle } from '../../helpers/PageTitle';

const movingTextData = [
  'INSPIRE',
  'INNOVATE',
  'INTEGRATE'
];

const portfolioData = [
  {
    imgUrl: '/images/portfolio_3.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
    category: 'digital',
  },
  {
    imgUrl: '/images/portfolio_1.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
    category: 'graphics',
  },
  {
    imgUrl: '/images/portfolio_2.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
    category: 'website',
  },
  {
    imgUrl: '/images/portfolio_3.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
    category: 'marketing',
  },
  {
    imgUrl: '/images/portfolio_4.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
    category: 'graphics',
  },
  {
    imgUrl: '/images/portfolio_3.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
    category: 'website',
  },
  {
    imgUrl: '/images/portfolio_1.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
    category: 'marketing',
  },
  {
    imgUrl: '/images/portfolio_2.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
    category: 'graphics',
  },
  {
    imgUrl: '/images/portfolio_3.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
    category: 'website',
  },
  {
    imgUrl: '/images/portfolio_4.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
    category: 'marketing',
  },
  {
    imgUrl: '/images/portfolio_3.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
    category: 'digital',
  },
];

const testimonialData = [
  {
    imgUrl: '/images/113x113.jpeg',
    rating: '5',
        desc: "Crescent IT is definitely one of the Highly professional, well organized, transparent with their expectations. It was a refreshing experience to deal with someone who actually cares and means what they say. I wish you guys immense success and hope you grow into one of the top consulting firms across the US.",
    name: 'Azmath Syed',
  //  designation: 'Web design',
  },
  {
      imgUrl: '/images/113x113.jpeg',
    rating: '5',
      desc: "From the initial consultation to the final implementation, their expertise, professionalism, and dedication to excellence have been impressive. They took the time to understand my specific needs, offered tailored solutions, and ensured a seamless execution.",
    name: 'Ismail Khan',
  //  designation: 'Web design',
  },
  {
      imgUrl: '/images/113x113.jpeg',
    rating: '5',
      desc: "Highly Recommend this IT Consultancy! Their team's expertise and customer service are unmatched",
    name: 'Sreeya Reddy',
  //  designation: 'Web design',
  },
  {
      imgUrl: '/images/113x113.jpeg',
    rating: '5',
      desc: "Great IT services, highly recommend!!",
    name: 'Ariyanna Ayala',
  //  designation: 'Web design',
  },
  {
      imgUrl: '/images/113x113.jpeg',
    rating: '5',
      desc: "I highly recommend Crescent IT Solutions Inc to anyone seeking reliable, efficient, and innovative IT solutions. They have earned my trust and loyalty, and I look forward to continuing our partnership.",
    name: 'Asif Mohd',
  //  designation: 'Web design',
  },
];

const teamData = [
  {
    imgUrl: '/images/630x808.jpeg',
    name: 'Adam Smith',
    designation: 'President & CEO',
    srcUrl: '/team/team-details',
  },
  {
      imgUrl: '/images/630x808.jpeg',
    name: 'Jacob King',
    designation: 'CMO',
    srcUrl: '/team/team-details',
  },
  {
      imgUrl: '/images/630x808.jpeg',
    name: 'Abraham Gray',
    designation: 'COO',
    srcUrl: '/team/team-details',
  },
  {
      imgUrl: '/images/630x808.jpeg',
    name: 'David Smith',
    designation: 'CFO',
    srcUrl: '/team/team-details',
  },
];

const funfactData = [
  {
    iconUrl: '/images/icons/funfact_icon_1.svg',
    funfactNumber: '50',
    title: 'Happy Clients',
  },
  {
    iconUrl: '/images/icons/funfact_icon_2.svg',
    funfactNumber: '35',
    title: 'Project Done',
  },
  {
    iconUrl: '/images/icons/funfact_icon_3.svg',
    funfactNumber: '20',
    title: 'Awards Win',
  },
  {
    iconUrl: '/images/icons/funfact_icon_4.svg',
    funfactNumber: '500',
    title: 'Profit Gain',
  },
];

const postData = [
    {
        imgUrl: '',
        title: 'Software Engineer',
        desc: 'Seeking qualified Software Engineer with Masters Degree or foreign equivalent CS OR ENGG OR CIS OR IT to Develop frond end using HTML',
        postUrl: '/Software-Engineer',
        postedBy: 'admin',
        adminUrl: '/',
        numberOfComment: 4,
        commentUrl: '/blog/blog-details',
    },
    {
        imgUrl: '/images/post_2.jpeg',
        title: 'Software Developer',
        desc: 'Develop, create, and modify general computer applications software or specialized utility programs. Analyze user needs and develop software solutions.',
        postUrl: '/Software-Developer',
        postedBy: 'admin',
        adminUrl: '/',
        numberOfComment: 5,
        commentUrl: '/blog/blog-details',
    },
    {
        imgUrl: '/images/post_3.jpeg',
        title: 'Senior .Net Developer',
        desc: 'Involve in software development life cycle (SDLC), which includes requirement gathering, design, coding, testing. Design and develop the application using framework Angular/React with HTML5, CSS3 and Bootstrap.',
        postUrl: '/Senior-Developer',
        postedBy: 'admin',
        adminUrl: '/',
        numberOfComment: 10,
        commentUrl: '/blog/blog-details',
    },
    {
        imgUrl: '/images/post_3.jpeg',
        title: 'Software Engineer',
        desc: 'Seeking qualified Software Engineer with Masters Degree or foreign equivalent CS OR ENGG OR CIS OR IT to Develop frond end using HTML.',
        postUrl: '/Software-Engineer',
        postedBy: 'admin',
        adminUrl: '/',
        numberOfComment: 2,
        commentUrl: '/blog/blog-details',
    },
    {
        imgUrl: '/images/post_3.jpeg',
        title: 'Java Developer',
        desc: 'We are seeking a highly skilled Java Developer with expertise in Microservices architecture and Spring Security to join our dynamic team. The successful candidate will play a key role in designing, developing, and implementing scalable and secure Java-based applications.',
        postUrl: '/Java-Developer',
        postedBy: 'admin',
        adminUrl: '/',
        numberOfComment: 10,
        commentUrl: '/blog/blog-details',
    },
];

export default function Home5() {
  pageTitle('Home');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroStyle5
        bgImg="/images/hero_video_bg.jpeg"
              title="Inspiring Innovation, Integrating Excellence."
              subTitle="Welcome to Crescent IT Solutions <br />  Elevate Your Business and Unlock Your Potential with Crescent IT Solutions"
        srcUrl="/contact"
      />

      <Section
        pt="135"
        ptLg="75"
        pb="140"
        pbLg="80"
        className="position-relative"
      >
        <AboutUsSection
          thumbUrl="/images/630x745.jpg"
          experienceBgUrl="/images/446x554.jpg"
          experienceIconUrl="/images/experience_icon.svg"
          experienceNumber="4"
          experienceTitle="Work Experience"
          sectionTitleUp="About Us"
          sectionTitle='Get Ready for Success with <span className="text-accent">Crescent IT Solutions</span> Your Path to Growth.'
                  sectionSubTitle="Crescent IT Solutions has been a beacon of innovation, offering comprehensive IT services and consulting solutions. Specializing in both IT and non-IT sectors, With a focus on collaboration and excellence, we strive to exceed expectations and drive sustainable growth in today's dynamic market since 2015.<br>
           <br> <h3>Our Mission</h3> Our mission is to improve patient care, financial management, and operational efficiency through cutting-edge technology solutions and exceptional staffing services. We strive to build long-lasting partnerships with our clients, supporting their growth and success every step of the way.

"
          grothTitle="Web development"
          grothPercentage="90"
          featureList={[
            {
              title: 'IT Services',
              subTitle:
                'Comprehensive IT services, delivering tailored solutions for your business needs with expertise and innovation.',
            },
            {
              title: 'Human Resources (HR) Consulting',
              subTitle: 'Assistance with HR-related tasks such as recruitment.',
            },
          ]}
          btnText="Get A Quote"
          btnUrl="/contact"
          videoBtnText="Watch the video"
                  videoBtnUrl="https://www.youtube.com/embed/dAZ_7uif0VI"
        />
      </Section>

      <Section>
        <MovingTextSection data={movingTextData} />
      </Section>

      <Section
        pt=""
        ptLg=""
        pb=""
        pbLg=""
        style={{ backgroundImage: `url('/images/gallery_bg.jpeg')` }}
        className="background-top-filled"
      >
        {/*<PortfolioSectionStyle2*/}
        {/*  sectionTitle="Explore Our Best <br/> Recent Work Projects"*/}
        {/*  sectionTitleUp="Our Latest Project"*/}
        {/*  data={portfolioData}*/}
        {/*/>*/}
      </Section>

      <Section pt="133" ptLg="75" pb="140" pbLg="80">
        <TestimonialSectionStyle2
          sectionTitleUp="Testimonial"
          sectionTitle="What They’re Saying?"
          sectionSubTitle="Providing legal advice, contract drafting, compliance assistance, intellectual<br/> property protection, and other legal support for businesses."
          data={testimonialData}
        />
      </Section>

      <Section pb="55" pbLg="0">
        <TeamSectionStyle2
          sectionTitle="Meet the professional team <br/>
            behind the success"
          sectionTitleUp="Meet Our Team Member"
          data={teamData}
        />
      </Section>

      <Section pt="0" ptLg="0" pb="0" pbLg="0">
        {/*<FunfactSection*/}
        {/*  sectionTitleUp="Company’s Statistic"*/}
        {/*  sectionTitle="Achievements of Our Crescent IT Solutions"*/}
        {/*  sectionSubTitle="Providing legal advice, contract drafting, compliance*/}
        {/*  assistance, intellectual property protection, and other legal*/}
        {/*  support."*/}
        {/*  data={funfactData}*/}
        {/*  imgUrl="/images/statistic_img.png"*/}
        {/*  shapeImgUrl="/images/shape_1.png"*/}
        {/*/>*/}
      </Section>

      <Section
        pt="133"
        ptLg="75"
        pb="140"
        pbLg="80"
        className="background-filled overflow-hidden"
        style={{ backgroundImage: `url(${'/images/cta_bg.png'})` }}
      >
        <ContactSection
          sectionTitleUp="Contact With Us"
          sectionTitle="Let’s Work Together?"
          sectionSubTitle=" Connect with Crescent IT Solutions: Where Innovation Meets Expertise!"
          textVarient="text-white"
          className="cs_mb_40"
          sectionImgUrl="images/cta_bg.png"
          contactInfo={[
            {
              imgUrl: '/images/icons/contact_icon_1.svg',
              titleUp: 'Have Any Question?',
                  title: '(708) 667-0871',
              textColor: 'text-white',
            },
            {
              imgUrl: '/images/icons/contact_icon_2.svg',
              titleUp: 'Send Email',
              title: 'hr@crescentitsolutions.com',
              textColor: 'text-white',
            },
            {
              imgUrl: '/images/icons/contact_icon_3.svg',
              titleUp: 'Address',
                title: '5901 N Cicero Ave, Ste 203, Chicago, IL 60646',
              textColor: 'text-white',
            },
          ]}
        />
      </Section>

      <Section pt="133" ptLg="75" pb="110" pbLg="50" className="cs_gray_bg">
        <PostSectionStyle5
          sectionTitleUp="We are hiring!!"
          sectionTitle="Apply Now."
          data={postData}
        />
      </Section>
    </>
  );
}
