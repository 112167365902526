import React, { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import SocialBtns from '../SocialBtns';
import { Icon } from '@iconify/react';
import { pageTitle } from '../../helpers/PageTitle';

export default function DetailsPage2() {
    pageTitle('Career');
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Breadcrumb title="Career" bgUrl="/images/career.jpg" />

            <section className="cs_pt_130 cs_pt_lg_80 cs_pb_93 cs_pb_lg_80">
                <div className="container">
                    <div>
                        <div className="cs_project_details-head d-flex align-items-center justify-content-between flex-wrap cs_mb_40 cs_row_gap_15 cs_column_gap_15">
                            <h2 className="m-0 cs_fs_48 cs_fs_lg_36">Software Developer </h2>
                            <div className="cs_published_date d-inline-flex cs_rounded_5 bg-accent text-white cs_pt_5 cs_pb_5 cs_pl_15 cs_pr_15 cs_pt_lg_3 cs_pb_lg_3">
                                15 May, 2024
                            </div>
                        </div>
                        {/*<img*/}
                        {/*  src="/images/project_details_img.jpeg"*/}
                        {/*  alt="Thumb"*/}
                        {/*  className="cs_mb_60 cs_mb_lg_40"*/}
                        {/*/>*/}
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="cs_project_details cs_pb_40">
                                    <p className="m-0">
                                        <h3>Roles and Responsibilities</h3>
                                        Strong proficiency with C#, .NET, data structures, basic algorithms, and applying objectoriented principles and design patterns. Strong understanding of architectural principles of software development, data modeling, and security. Strong proficiency with development tools such as Visual Studio and Git. Proficiency with relational databases design and query construction and optimization (SQL Server preferred).
                                        <ul>
                                            <li>Experience building Desktop applications with technologies such as WPF, UWP, or Windows Forms.
                                            </li>
                                            <li>Experience developing database stored procedures using T/SQL or PL-SQL
                                            </li>
                                            <li>Experience with Object-Relational tools such as Entity Framework, EF Core, Dapper, or Hibernate
                                            </li>
                                            <li>Experience designing and implementing efficient RESTful APis.
                                            </li>
                                            <li>Experience implementing unit and integration tests with NUnit, XUnit, MS Test, Selenium or similar.
                                            </li>
                                            <li>Self-motivated to stay current in development technologies, tools, and implementation patterns and a desire to share this knowledge within teams and the broader organization.
                                            </li>
                                        </ul>
                                    </p>{' '}
                                    <br />
                                    <h3 className="text-accent cs_fs_20 cs_lh_base m-0">
                                        Required Skills and Qualifications:
                                    </h3>{' '}
                                    <br />
                                    <p className="cs_mb_35">
                                        5-year degree (Computer Science, Information Systems, or relational functional field) and/or equivalent combination of education or work experience.


                                    </p>
                                    {/*<h4 className="cs_fs_18 cs_mb_12">Project Challenges : </h4>*/}
                                    {/*<ul className="cs_mb_30 cs_list cs_style_1">*/}
                                    {/*  <li>*/}
                                    {/*    <Icon icon="fa-solid:check" />*/}
                                    {/*    Nemo enim ipsam voluptatem quia voluptas mus et iusto odio*/}
                                    {/*  </li>*/}
                                    {/*  <li>*/}
                                    {/*    <Icon icon="fa-solid:check" />*/}
                                    {/*    Vivamus vel eros vitae est aliquet pellentesque*/}
                                    {/*  </li>*/}
                                    {/*  <li>*/}
                                    {/*    <Icon icon="fa-solid:check" />*/}
                                    {/*    If you are going to use a passage of Lorem Ipsum*/}
                                    {/*  </li>*/}
                                    {/*</ul>*/}
                                    <h3 className="text-accent cs_fs_20 cs_lh_base m-0">
                                        Qualified Candidates Should Send Their Resumes To:
                                    </h3>{' '}
                                    <p className="m-0">
                                        <a href="mailto:hr@crescentitsolutions.com" className="text-accent cs_fs_20 cs_lh_base m-0">hr@crescentitsolutions.com</a> Or Drop at
                                        Crescent IT Solutions Inc. 5901 N Cicero Ave, Suite 203 Chicago, IL 60646 Phone: <a href="tel:7086670871">
                                            (708) 667-0871</a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <ul className="cs_project_details-info bg-white cs_rounded_20 m-0 cs_pl_50 cs_pt_50 cs_pr_50 cs_pb_50 cs_pl_lg_40 cs_pt_lg_40 cs_pr_lg_40 cs_pb_lg_40">
                                    <li>
                                        <h3 className="cs_fs_18 cs_mb_10">Position:</h3>
                                        <p className="m-0">Software Developer</p>
                                    </li>
                                    {/*<li>*/}
                                    {/*  <h3 className="cs_fs_18 cs_mb_10">Client:</h3>*/}
                                    {/*  <p className="m-0">Kristin Watson</p>*/}
                                    {/*</li>*/}

                                    <li>
                                        <h3 className="cs_fs_18 cs_mb_10">Date of Opening :</h3>
                                        <p className="m-0">15 May 2024</p>
                                    </li>

                                    <li>
                                        <h3 className="cs_fs_18 cs_mb_10">Date of Closing :</h3>
                                        <p className="m-0">OPEN</p>
                                    </li>
                                    <li>
                                        <h3 className="cs_fs_18 cs_mb_10">Working Days and Hours: :</h3>
                                        <p className="m-0">Monday to Friday, 9:00 am to 5:00 pm</p>
                                    </li>
                                    <li>
                                        <h3 className="cs_fs_18 cs_mb_10">Salary :  </h3>
                                        <p className="m-0">$48,000 {'-'} $72,000 Per Annum</p>
                                    </li>
                                    <li>
                                        <h3 className="cs_fs_18 cs_mb_10">Location :</h3>
                                        <p className="m-0">{/*5901 N Cicero Ave, */}Chicago, IL</p>
                                    </li>
                                    {/*<li>*/}
                                    {/*  <SocialBtns variant="cs_social_btns d-flex flex-wrap cs_column_gap_15 cs_row_gap_15 cs_transition_5" />*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
