import React from 'react';
import { Link } from 'react-router-dom';
import SocialBtns from '../../components/SocialBtns';
import MenuWidget from '../Widget/MenuWidget';
import NewsLetterWidget from '../Widget/NewsLetterWidget';
const menuDataOne = [
  { title: 'About', href: '/about' },
  //{ title: 'Portfolio', href: '/portfolio' },
  { title: 'Services', href: '/service' },
  { title: 'Support', href: '/contact' },
  //{ title: 'Pricing Page', href: '/pricing' },
  //{ title: 'Our Store', href: '/shop' },
  //{ title: 'Blog', href: '/blog' },
  //{ title: 'Privacy Policy', href: '/' },
  { title: 'Contact', href: '/contact' },
];
export default function Footer() {
  return (
    <footer
      className="cs_footer background-filled text-white"
      style={{ backgroundImage: 'url("/images/1920x592.png")' }}
    >
      <div className="container">
        <div className="cs_footer_top d-flex justify-content-between align-items-start align-items-md-center cs_pt_46 cs_pb_46 cs_column_gap_15 cs_row_gap_15 flex-md-row  flex-column">
          <div className="cs_footer_contact_info">
            <h3 className="text-accent fw-normal cs_mb_4 cs_fs_16 cs_lh_lg">
              Have Any Question?
            </h3>
            <h2 className="text-white fw-medium m-0 cs_fs_22">
                          <a href="tel:7086670871">
                               (708) 667-0871</a>
            </h2>
          </div>
          <div className="cs_footer_logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <div className="cs_footer_contact_info">
            <h3 className="text-accent fw-normal cs_mb_4 cs_fs_16 cs_lh_lg">
              Send Email
            </h3>
            <h2 className="text-white fw-medium m-0 cs_fs_22">
                          <a href="mailto:hr@crescentitsolutions.com">hr@crescentitsolutions.com</a>
            </h2>
          </div>
        </div>
        <div className="cs_footer_main cs_pt_30 cs_pb_30">
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item cs_pt_20 cs_pb_20">
                <div className="cs_text_widget">
                  <p>
                                      <span className="text-accent">Crescent IT Solutions</span>     {/* <b>Crescent IT Solutions</b>*/}  has been a beacon of innovation, offering comprehensive IT services and consulting solutions. Our commitment to inspiring creativity and integrating cutting-edge technologies sets us apart. Specializing in both IT and non-IT sectors, we provide tailored strategies to meet the diverse needs of our clients. With a focus on collaboration and excellence, we strive to exceed expectations and drive sustainable growth in today's dynamic market since 2015.
                  </p>
                </div>
                <SocialBtns variant="cs_social_btns d-flex flex-wrap cs_column_gap_15 cs_row_gap_15 cs_transition_5" />
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1">
              <MenuWidget data={menuDataOne} title="Explore" />
            </div>
            {/*<div className="col-lg-3">*/}
            {/*  <div className="cs_footer_item cs_pt_20 cs_pb_20">*/}
            {/*    <NewsLetterWidget*/}
            {/*      title="Newsletter"*/}
            {/*      subTitle="Sign-up for the most recent updates.  "*/}
            {/*                  />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="cs_copyright text-center cs_fs_124 cs_lh_lg cs_pt_36 cs_pb_36">
        <div className="container">
          <p className="m-0">
            Copyright © {new Date().getFullYear()} <Link to="/">Crescent IT Solutions</Link>.
            All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
