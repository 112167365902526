import React, { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import AboutUsSection from '../Section/AboutSection';
import ServiceSection from '../Section/ServiceSection';
import TeamSection from '../Section/TeamSection';
import TestimonialSection from '../Section/TestimonialSection';
import { pageTitle } from '../../helpers/PageTitle';

const serviceData = [
  {
    iconUrl: '/images/icons/service_icon_2.svg',
    title: 'IT Services',
    subTitle:
      'Innovative IT Services and Consulting for Sustainable Growth and Excellence.',
    imgUrl: '/images/IT.jpg',
    href: '/service/services-details',
  },
  {
    iconUrl: '/images/icons/service_icon_1.svg',
    title: 'Staffing',
    subTitle:
      'Expert Staffing Solutions for IT and Non-IT Sectors, Ensuring Top Talent and Optimal Fit.',
    imgUrl: '/images/business.jpg',
    href: '/service/services-details',
  },
  {
    iconUrl: '/images/icons/service_icon_3.svg',
    title: 'Business Consulting',
    subTitle:
      'Strategic Business Consulting for Innovation and Sustainable Growth.',
    imgUrl: '/images/staffing.jpg',
    href: '/service/services-details',
  },
  {
    iconUrl: '/images/icons/service_icon_4.svg',
    title: 'Human Resources',
    subTitle:
      'Comprehensive Human Resource Solutions for Talent Management and Organizational Success.',
    imgUrl: '/images/hr.png',
    href: '/service/services-details',
  },
];

const teamData = [
{
    imgUrl: '/images/630x808.jpeg',
    name: 'Andrew Adam',
    designation: 'President & CEO',
    srcUrl: '/team/team-details',
  },
  {
      imgUrl: '/images/630x808.jpeg',
    name: 'Jacob King',
    designation: 'CMO',
    srcUrl: '/team/team-details',
  },
  {
      imgUrl: '/images/630x808.jpeg',
    name: 'Abraham Gray',
    designation: 'COO',
    srcUrl: '/team/team-details',
  },
  {
      imgUrl: '/images/630x808.jpeg',
    name: 'David Smith',
    designation: 'CFO',
    srcUrl: '/team/team-details',
  },
];

const testimonialData = [
    {
        imgUrl: '/images/113x113.jpeg',
        rating: '5',
        desc: "Crescent IT is definitely one of the Highly professional, well organized, transparent with their expectations. It was a refreshing experience to deal with someone who actually cares and means what they say. I wish you guys immense success and hope you grow into one of the top consulting firms across the US.",
        name: 'Azmath Syed',
        //  designation: 'Web design',
    },
    {
        imgUrl: '/images/113x113.jpeg',
        rating: '5',
        desc: "From the initial consultation to the final implementation, their expertise, professionalism, and dedication to excellence have been impressive. They took the time to understand my specific needs, offered tailored solutions, and ensured a seamless execution.",
        name: 'Ismail Khan',
        //  designation: 'Web design',
    },
    {
        imgUrl: '/images/113x113.jpeg',
        rating: '5',
        desc: "Highly Recommend this IT Consultancy! Their team's expertise and customer service are unmatched",
        name: 'Sreeya Reddy',
        //  designation: 'Web design',
    },
    {
        imgUrl: '/images/113x113.jpeg',
        rating: '5',
        desc: "Great IT services, highly recommend!!",
        name: 'Ariyanna Ayala',
        //  designation: 'Web design',
    },
    {
        imgUrl: '/images/113x113.jpeg',
        rating: '5',
        desc: "I highly recommend Crescent IT Solutions Inc to anyone seeking reliable, efficient, and innovative IT solutions. They have earned my trust and loyalty, and I look forward to continuing our partnership.",
        name: 'Asif Mohd',
        //  designation: 'Web design',
    },
];

export default function AboutPage() {
  pageTitle('About Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb title="About Us" bgUrl="/images/1920x639.png" />

      <Section
        pt="135"
        ptLg="75"
        pb="140"
        pbLg="80"
        className="position-relative"
      >
              <AboutUsSection
                  thumbUrl="/images/630x745.jpg"
                  experienceBgUrl="/images/446x554.jpg"
                  experienceIconUrl="/images/experience_icon.svg"
                  experienceNumber="4"
                  experienceTitle="Work Experience"
                  sectionTitleUp="About Us"
                  sectionTitle='Welcome to <span className="text-accent">Crescent IT Solutions.</span> '
                  sectionSubTitle="
At Crescent IT Solutions, we're dedicated to empowering the healthcare industry through cutting-edge technology solutions and comprehensive staffing services. With a nationwide presence,<br><br> <h5> We specialize in:</h5>
<ul>
<li>
Custom Software Development: We design and develop tailored software solutions for healthcare providers, labs, and organizations, streamlining clinical and financial workflows.
</li>
<li>
Website Development and Maintenance: Our team creates user-friendly, responsive websites that enhance patient engagement, online presence, and financial data management.
</li>
<li>
Application Development and Maintenance: We design, build, and maintain custom applications that meet the specific needs of healthcare organizations.
</li>
<li>
Information Technology Staffing and Recruitment: We provide skilled IT professionals to support healthcare organizations, ensuring they have the expertise they need to succeed.
</li>
<li>
Non-IT Staffing and Recruitment: We offer talented professionals for non-technical roles, from administration to leadership positions.
</li>
<li>
Outsourcing Augmentation: We supplement our clients' teams with our expertise, providing additional support and guidance when needed.
</li>
</ul>
<h5>Our Story</h5>

Founded on the principles of innovation and excellence, Crescent IT Solutions was established to bridge the gap between healthcare and technology. Our team of experts, with years of experience in the industry, came together to create a company that would revolutionize the way healthcare organizations operate.

<br><br><h5>Our Mission</h5>

Our mission is to improve patient care, financial management, and operational efficiency through cutting-edge technology solutions and exceptional staffing services. We strive to build long-lasting partnerships with our clients, supporting their growth and success every step of the way.
<br>
<br>
<h5>Our Values</h5>
<ul>
<li>
Patient-centered approach
</li>
<li>
Innovation and creativity
</li>
<li>
Collaboration and partnership
</li>
<li>
Excellence and quality
</li>
<li>
Integrity and transparency
</li>
</ul>

<h5>Join Us</h5>

At Crescent IT Solutions, we're committed to making a positive impact in the healthcare industry. If you share our passion for innovation, excellence, and patient care, join us in our mission to transform healthcare through technology and staffing expertise."
                  grothTitle="Web development"
                  grothPercentage="90"
                  featureList={[
                      {
                          title: 'IT Services',
                          subTitle:
                              'Comprehensive IT services, delivering tailored solutions for your business needs with expertise and innovation.',
                      },
                      {
                          title: 'Human Resources (HR) Consulting',
                          subTitle: 'Assistance with HR-related tasks such as recruitment.',
                      },
                  ]}
                  btnText="Get A Quote"
                  btnUrl="/contact"
                  videoBtnText="Watch the video"
                  videoBtnUrl="https://www.youtube.com/embed/dAZ_7uif0VI"
              />
      </Section>

      <Section pt="140" ptLg="80" pb="130" pbLg="70" className="bg-primary">
        <ServiceSection
          data={serviceData}
          sectionTitleDown=""
          sectionBtnText="Find More Services"
          sectionBtnUrl="/service"
          textVariant="text-white"
        />
      </Section>

      <Section pt="133" ptLg="75">
        <TeamSection
          data={teamData}
          sectionTitle="Meet the professional team <br/>behind the success"
          sectionTitleUp="Meet Our Team Member"
        />
      </Section>

      <Section
        pt="110"
        ptLg="55"
        pb="140"
        pbLg="80"
        style={{ backgroundImage: `url(${'/images/testimonial_bg.jpeg'})` }}
      >
        <TestimonialSection
          sectionTitleUp="Testimonial"
          sectionTitle="What They’re Saying?"
          sectionSubTitle="Providing legal advice, contract drafting, compliance assistance, intellectual property protection, and other legal support for businesses."
          data={testimonialData}
        />
      </Section>
    </>
  );
}
