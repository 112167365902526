import { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import Gallery from '../Gallery';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';

const portfolioData = [
    'INSPIRE',
    'INNOVATE',
    'INTEGRATE'
];

export default function PortfolioPage() {
  pageTitle('Portfolio');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb title="Our Portfolio" bgUrl="/images/page_header_1.jpeg" />
      <Section pt="130" ptLg="75" pb="125" pbLg="55">
        <div className="container">
          <Gallery data={portfolioData} />
        </div>
      </Section>
    </>
  );
}
