import React, { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import SocialBtns from '../SocialBtns';
import { Icon } from '@iconify/react';
import { pageTitle } from '../../helpers/PageTitle';

export default function DetailsPage3() {
    pageTitle('Career');
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Breadcrumb title="Career" bgUrl="/images/career.jpg" />

            <section className="cs_pt_130 cs_pt_lg_80 cs_pb_93 cs_pb_lg_80">
                <div className="container">
                    <div>
                        <div className="cs_project_details-head d-flex align-items-center justify-content-between flex-wrap cs_mb_40 cs_row_gap_15 cs_column_gap_15">
                            <h2 className="m-0 cs_fs_48 cs_fs_lg_36">Senior .Net Developer</h2>
                            <div className="cs_published_date d-inline-flex cs_rounded_5 bg-accent text-white cs_pt_5 cs_pb_5 cs_pl_15 cs_pr_15 cs_pt_lg_3 cs_pb_lg_3">
                                20 May, 2024
                            </div>
                        </div>
                        {/*<img*/}
                        {/*  src="/images/project_details_img.jpeg"*/}
                        {/*  alt="Thumb"*/}
                        {/*  className="cs_mb_60 cs_mb_lg_40"*/}
                        {/*/>*/}
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="cs_project_details cs_pb_40">
                                    <p className="m-0">
                                        <h3>Roles and Responsibilities</h3>
                                        Involve in software development life cycle (SDLC), which includes requirement gathering, design, coding, testing. Design and develop the application using framework Angular/React with HTML5, CSS3 and Bootstrap. Work with ASP .NET Core, WEB API, Entity Framework Core, Dependency Injection. Involved in migrating the application from Angular 2.0 to Angular 9/10 and .Net 4.5 to Net CORE 2.1. Develop RESTFul /Web API as Service layer in .Net utilizing C# Work on Windows Azure Services like PaaS, IaaS and work on storages like Blob (Page and Block), Sql Azure. Automate various infrastructure activities like Continuous Deployment using Jenkins on AZURE. Use JIRA tool for Issue/bug tracking, monitoring of work assignment in the system. Ability to propose different ways to solve the problem and showcase why one option is better than other. Travel may be required to unanticipated work site locations for consulting.
                                    </p>{' '}
                                    <br />
                                    <h3 className="text-accent cs_fs_20 cs_lh_base m-0">
                                        Required Skills and Qualifications:
                                    </h3>{' '}
                                    <br />
                                    <p className="cs_mb_35">
                                        4-year degree (Computer Science, Information Systems, or relational functional field) and/or equivalent combination of education or work experience.
                                    </p>
                                    {/*<h4 className="cs_fs_18 cs_mb_12">Project Challenges : </h4>*/}
                                    {/*<ul className="cs_mb_30 cs_list cs_style_1">*/}
                                    {/*  <li>*/}
                                    {/*    <Icon icon="fa-solid:check" />*/}
                                    {/*    Nemo enim ipsam voluptatem quia voluptas mus et iusto odio*/}
                                    {/*  </li>*/}
                                    {/*  <li>*/}
                                    {/*    <Icon icon="fa-solid:check" />*/}
                                    {/*    Vivamus vel eros vitae est aliquet pellentesque*/}
                                    {/*  </li>*/}
                                    {/*  <li>*/}
                                    {/*    <Icon icon="fa-solid:check" />*/}
                                    {/*    If you are going to use a passage of Lorem Ipsum*/}
                                    {/*  </li>*/}
                                    {/*</ul>*/}
                                    <h3 className="text-accent cs_fs_20 cs_lh_base m-0">
                                        Qualified Candidates Should Send Their Resumes To:
                                    </h3>{' '}
                                    <p className="m-0">
                                        <a href="mailto:hr@crescentitsolutions.com" className="text-accent cs_fs_20 cs_lh_base m-0">hr@crescentitsolutions.com</a> Or Drop at
                                        Crescent IT Solutions Inc. 5901 N Cicero Ave, Suite 203 Chicago, IL 60646 Phone: <a href="tel:7086670871">
                                            (708) 667-0871</a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <ul className="cs_project_details-info bg-white cs_rounded_20 m-0 cs_pl_50 cs_pt_50 cs_pr_50 cs_pb_50 cs_pl_lg_40 cs_pt_lg_40 cs_pr_lg_40 cs_pb_lg_40">
                                    <li>
                                        <h3 className="cs_fs_18 cs_mb_10">Position:</h3>
                                        <p className="m-0">Senior .Net Developer</p>
                                    </li>
                                    {/*<li>*/}
                                    {/*  <h3 className="cs_fs_18 cs_mb_10">Client:</h3>*/}
                                    {/*  <p className="m-0">Kristin Watson</p>*/}
                                    {/*</li>*/}

                                    <li>
                                        <h3 className="cs_fs_18 cs_mb_10">Date of Opening :</h3>
                                        <p className="m-0">20 May 2024</p>
                                    </li>

                                    <li>
                                        <h3 className="cs_fs_18 cs_mb_10">Date of Closing :</h3>
                                        <p className="m-0">OPEN</p>
                                    </li>
                                    <li>
                                        <h3 className="cs_fs_18 cs_mb_10">Working Days and Hours: :</h3>
                                        <p className="m-0">Monday to Friday, 9:00 am to 5:00 pm</p>
                                    </li>
                                    <li>
                                        <h3 className="cs_fs_18 cs_mb_10">Salary :</h3>
                                        <p className="m-0"> $40,000 {'-'} $68,000</p>
                                    </li>
                                    <li>
                                        <h3 className="cs_fs_18 cs_mb_10">Location :</h3>
                                        <p className="m-0">{/*5901 N Cicero Ave, */}Chicago, IL</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
