import { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import BrandSection from '../Section/BrandSection';
import ServiceSectionStyle4 from '../Section/ServiceSection/ServiceSectionStyle4';
import TestimonialSection from '../Section/TestimonialSection';
import { pageTitle } from '../../helpers/PageTitle';

const serviceData = [
    {
        iconUrl: '/images/icons/service_icon_2.svg',
        title: 'IT Services',
        subTitle:
            'Innovative IT Services and Consulting for Sustainable Growth and Excellence.',
        imgUrl: '/images/IT.jpg',
        href: '/service/services-details',
    },
    {
        iconUrl: '/images/icons/service_icon_1.svg',
        title: 'Staffing',
        subTitle:
            'Expert Staffing Solutions for IT and Non-IT Sectors, Ensuring Top Talent and Optimal Fit.',
        imgUrl: '/images/business.jpg',
        href: '/service/services-details',
    },
    {
        iconUrl: '/images/icons/service_icon_3.svg',
        title: 'Business Consulting',
        subTitle:
            'Strategic Business Consulting for Innovation and Sustainable Growth.',
        imgUrl: '/images/staffing.jpg',
        href: '/service/services-details',
    },
    {
        iconUrl: '/images/icons/service_icon_4.svg',
        title: 'Human Resources',
        subTitle:
            'Comprehensive Human Resource Solutions for Talent Management and Organizational Success.',
        imgUrl: '/images/hr.png',
        href: '/service/services-details',
    },
];

const brandData = [
  { imgUrl: '/images/brand_1.png' },
  { imgUrl: '/images/brand_2.png' },
  { imgUrl: '/images/brand_3.png' },
  { imgUrl: '/images/brand_4.png' },
  { imgUrl: '/images/brand_5.jpeg' },
];

const testimonialData = [
    {
        imgUrl: '/images/113x113.jpeg',
        rating: '5',
        desc: "Crescent IT is definitely one of the Highly professional, well organized, transparent with their expectations. It was a refreshing experience to deal with someone who actually cares and means what they say. I wish you guys immense success and hope you grow into one of the top consulting firms across the US.",
        name: 'Azmath Syed',
        //  designation: 'Web design',
    },
    {
        imgUrl: '/images/113x113.jpeg',
        rating: '5',
        desc: "From the initial consultation to the final implementation, their expertise, professionalism, and dedication to excellence have been impressive. They took the time to understand my specific needs, offered tailored solutions, and ensured a seamless execution.",
        name: 'Ismail Khan',
        //  designation: 'Web design',
    },
    {
        imgUrl: '/images/113x113.jpeg',
        rating: '5',
        desc: "Highly Recommend this IT Consultancy! Their team's expertise and customer service are unmatched",
        name: 'Sreeya Reddy',
        //  designation: 'Web design',
    },
    {
        imgUrl: '/images/113x113.jpeg',
        rating: '5',
        desc: "Great IT services, highly recommend!!",
        name: 'Ariyanna Ayala',
        //  designation: 'Web design',
    },
    {
        imgUrl: '/images/113x113.jpeg',
        rating: '5',
        desc: "I highly recommend Crescent IT Solutions Inc to anyone seeking reliable, efficient, and innovative IT solutions. They have earned my trust and loyalty, and I look forward to continuing our partnership.",
        name: 'Asif Mohd',
        //  designation: 'Web design',
    },
];

export default function ServicesPage() {
  pageTitle('Services');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb title="Our Services" bgUrl="/images/1920x639.png" />

      <Section pt="140" ptLg="80" pb="115" pbLg="55">
        <ServiceSectionStyle4
          sectionTitleUp="Our Service List"
          sectionTitle="We Provide The Solution <br />For Our Clients"
          data={serviceData}
        />
      </Section>

      <Section pt="110" ptLg="80" pb="110" pbLg="80" className="bg-gray">
        <BrandSection data={brandData} />
      </Section>

      <Section
        pt="140"
        ptLg="75"
        pb="135"
        pbLg="75"
        style={{ backgroundImage: `url(${'/images/testimonial_bg.jpeg'})` }}
      >
        <TestimonialSection
          sectionTitleUp="Testimonial"
          sectionTitle="What They’re Saying?"
          sectionSubTitle="Providing legal advice, contract drafting, compliance assistance, intellectual property protection, and other legal support for businesses."
          data={testimonialData}
        />
      </Section>
    </>
  );
}
