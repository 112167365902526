import React, { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import LocationInMap from '../LocationInMap';
import Section from '../Section';
import FormStyle2 from '../Form/FormStyle2';
import IconboxStyle2 from '../Iconbox/IconboxStyle2';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';

const locationData =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2965.527919567613!2d-87.75062062337759!3d41.98894445846617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fcf8ba269a2f7%3A0x1252e4d8ab0cecde!2sCrescent%20IT%20Solutions%20Inc!5e0!3m2!1sen!2sus!4v1715722962704!5m2!1sen!2sus';

export default function ContactPage() {
  pageTitle('Contact');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb title="Contact" bgUrl="/images/1920X639.PNG" />
      <Section pt="133" ptLg="75" pb="133" pbLg="75">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6">
              <SectionHeadingStyle3
                sectionTitleUp="Have any question?"
                sectionTitle="Write a Message"
              />
              <FormStyle2 />
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="cs_pl_60 cs_pl_lg_0 cs_pt_lg_60">
                <SectionHeadingStyle3
                  sectionTitleUp="Contact With Us"
                  sectionTitle="Let’s work together"
                  sectionSubTitle=""
                  className="cs_mb_40"
                />
                <div className="cs_iconbox_wrapper cs_style1">
                  <IconboxStyle2
                    imgUrl="/images/icons/contact_icon_1.svg"
                                      title=
                                          "(708) 667-0871"
                    titleUp="Have Any Question?"
                  />
                  <IconboxStyle2
                    imgUrl="/images/icons/contact_icon_2.svg"
                                      title="hr@crescentitsolutions.com"
                    titleUp="Send Email"
                  />
                  <IconboxStyle2
                    imgUrl="/images/icons/contact_icon_3.svg"
                                      title="5901 N Cicero Ave, Ste 203, Chicago, IL 60646"
                    titleUp="Address"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <LocationInMap data={locationData} />
    </>
  );
}
