import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function SocialBtns({ variant }) {
  return (
    <div className={`${variant ? variant : ''}`}>
          <Link
              to="https://www.linkedin.com/company/crescentitsolutions/" target="_blank"
              className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35 text-white rounded-circle"
          >
              <Icon icon="fa6-brands:linkedin" />
          </Link>
      <Link
              to="https://www.facebook.com/share/WXYkp5dC4gv8U8dT/?mibextid=LQQJ4d/" target="_blank"
        className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35 text-white rounded-circle"
      >
        <Icon icon="fa6-brands:facebook" />
      </Link>
      <Link
              to="https://www.instagram.com/crescentitsolutions?igsh=ZGp3dXc4ZjBybGpp&utm_source=qr/" target="_blank"
        className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35 text-white rounded-circle"
      >
              <Icon icon="fa6-brands:instagram" />
      </Link>
      {/*<Link*/}
      {/*        to="/" */}
      {/*  className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35 text-white rounded-circle"*/}
      {/*>*/}
      {/*  <Icon icon="fa6-brands:youtube" />*/}
      {/*</Link>*/}
    </div>
  );
}
